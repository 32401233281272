<template>
  <div class="">
    <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
      <Steps :current="1" />
    </div>
    <div class="grid justify-items-center">
      <div class="bg-white shadow sm:rounded-lg lg:w-1/2 sm:w-full">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Claim your @blockbase.dev email:
          </h3>
          <div class="mt-2 text-sm text-gray-500">
            <p>
              Pick any valid email that's at least 5 characters long.
            </p>
          </div>
          <form class="mt-5 sm:flex sm:items-center" @submit.prevent="onSubmit" @submit="claim">
            <label for="email" class="sr-only">Email</label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <input v-model="address" ref="email" type="text" name="email" id="email" :class="inputClass" placeholder="youremail" />
              <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                @blockbase.dev
              </span>
            </div>
            <div class="mt-1">
              <button type="submit" :disabled="busy" class="mt-1 w-full inline-flex items-center justify-center pl-2 pr-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm">
                <span v-if="busy">
                  <svg class="mr-2 animate-spin inline h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Loading...
                </span>
                <span v-else-if="errMessage">
                  Try again
                </span>
                <span v-else>
                  Claim it!
                </span>
              </button>
            </div>
          </form>
          <div class="px-4 py-0 sm:p-6 max-w-sm text-sm text-red-500" v-if="this.errMessage">
            {{ errMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import Steps from '@/components/Steps'

const emailUserPart = /^[a-z\d\.\-=\^_]+$/i

export default {
  components: {
    Steps,
  },
  data () {
    return {
      address: '',
      errMessage: null,
      inputValid: true,
      busy: false,
    }
  },
  computed: {
    inputClass() {
      if (this.inputValid) {
        return 'flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300'
      }
      return 'flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-red-500 focus:border-red-500 sm:text-sm border-red-500'
    }
  },
  mounted() {
    this.$refs.email.focus()
  },
  methods: {
    async claim() {
      this.busy = true
      if (this.address.length < 5) {
        this.inputValid = false
        this.errMessage = 'Address must be at least 5 characters.'
        this.busy = false
        return
      }
      if (this.address.length > 512) {
        this.inputValid = false
        this.errMessage = 'Address must be no more than 512 characters.'
        this.busy = false
        return
      }
      if (!emailUserPart.test(this.address)) {
        this.inputValid = false
        this.errMessage = 'Address contains invalid characters. Valid special characters are: – = ^ _ .'
        this.busy = false
        return
      }

      this.inputValid = true

      Sentry.setContext("address", { "address": this.address })
      try {
        const result = await this.axios.post(process.env.VUE_APP_API_URL + 'claim', {
          address: this.address
        }, { headers: { authorization: 'Bearer ' + this.$auth.token.value } })
        if (result.data.message && result.data.message === 'claimed') {
          this.connect(this.address)
          return
        }
        if (result.data.error) {
          this.errMessage = result.data.error
          Sentry.captureException(new Error(`Claim POST response: ${this.errMessage}`))
        } else {
          this.errMessage = null
        }
      } catch (err) {
        this.errMessage = "We ran into an internal error - not your fault! You can try again, but rest assured we have captured and will investigate this error."
        Sentry.captureException(new Error(`Claim POST: ${err}`))
        setTimeout(() => {
          this.busy = false
          this.errMessage = null
        }, 4000)
        return
      }
      this.inputValid = false
      this.busy = false
    },
    connect (theaddress) {
      this.$router.push({ path: '/connect/' + this.address })
    },
  }
}
</script>
